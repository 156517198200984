<template>
  <v-card>
    <dialogTitle :title="dialogProps.dialogTitle">
      <v-btn color="white" icon @click.stop="openHelpDocument(result['additionlFormAtts'])">
        <v-icon>mdi-help-circle</v-icon>
      </v-btn>

      <v-btn icon dark @click="toggleMaximize()">
        <v-icon v-if="!dialogProps.dialogFormat.maximized">mdi-window-maximize</v-icon>
        <v-icon v-else>mdi-window-restore</v-icon>
      </v-btn>

      <v-btn icon dark @click="$store.commit('closeDialog',  result.dialogRef)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </dialogTitle>

    <dialogContent :result="{}">
      <slot>
        <atable :result="result" v-on="$listeners"></atable>
      </slot>
    </dialogContent>
  </v-card>
</template>
<script>
//import { getdefaultFormHeader, getRandomInt } from "@/js/helper.js";

import atable from "@/commonComponents/dynamic/table.vue";

import dialogTitle from "@/commonComponents/dialogTitle.vue";
import dialogContent from "@/commonComponents/dialogContent.vue";

export default {
  components: {
    atable,
    dialogTitle,
    dialogContent,
  },
  // data: function () {
  //   return {
  //     formHeader: getdefaultFormHeader(this.result),
  //     isValid: true,
  //     docNoticePopup: false,
  //     dialog: false,
  //     formRef: "form" + getRandomInt(0, 1000),
  //     uploadedFiles: {},
  //   };
  // },
  mounted() {
    let localResult = { ...this.result };

    this.$set(localResult, "formRef", this.formRef);
    this.$set(localResult, "formRefObject", this.$refs[this.formRef]);
   /* console.log("this", this);
    console.log("formRef", this.formRef);
    console.log( "formRefObject", this.$refs[this.formRef]);
    console.log(
      "setting form referance",
      localResult,
      this.$refs[this.formRef],
      this.$refs,
      this.formRef
    );*/
    this.$emit("dialog-result-changed", localResult);
  },
  props: ["result"],
  computed: {
    dialogProps() {
      console.log("result",this.result);
      let dialogProps = { dialogFormat: {} };
      if (typeof this.result.json != "undefined") {
        dialogProps = this.result.json;
      }
      return dialogProps;
    },
  },
  methods: {
  
    // registerFile(fileName, file) {
    //   console.log("uploaded updateing", fileName, file);
    //   this.$set(this.uploadedFiles, fileName, file);
    // },
    openHelpDocument(docURL) {
      if ( typeof docURL !== "undefined" &&
        typeof docURL["data-help-link"] !== "undefined" &&
          docURL["data-help-link"] != ""
      ) {
        window.open(docURL["data-help-link"], "_blank");
      } else {
        this.docNoticePopup = true; //send to user Notice!
        let source = this.$route.fullPath;
        let className =
          typeof docURL["data-className"] != "undefined"
            ? docURL["data-className"]
            : "";
        let functionName =
          typeof docURL["data-callingFunction"] != "undefined"
            ? docURL["data-callingFunction"]
            : "";
        let currentNeedle = className + "_" + functionName;
        if (currentNeedle != window.clickHelpFlag) {
          window.clickHelpFlag = currentNeedle;
          this.sendTeamEmail(source, className, functionName);
        }
      }
    },
    toggleMaximize() {
      let localResult = { ...this.result };
      let maximized = !this.result.json.dialogFormat.maximized;
      this.$set(
        localResult.json.dialogFormat,
        "maximized",
        !this.result.json.dialogFormat.maximized
      );
      this.$emit("dialog-result-changed", localResult);

      let dialogType = this.result["additionlFormAtts"]["data-callingFunction"];
      if (typeof dialogType != "undefined") {
        let width = this.result.json.dialogFormat.width;
        let url = this.result.tableUrl;

        this.frameworkAxiosRequest({
          method: 'post',
          url: url,
          data: {
            function: 'setDialogWidth',
            dialogType: dialogType,
            width: width,
            maximized: maximized,
          },
        })
            .then(function (response) {
            //handle success
            console.log(response);
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      }
    },
    // revalidate() {
    //   console.log("revalidating");
    //   this.$refs[this.formRef].validate();
    // },
    // submitForm(functionName) {
    //   //  console.log(this.$refs.form.validate(), this.isValid);
    //   if (this.$refs[this.formRef].validate()) {
    //     console.log(this.result);
    //     this.ajaxSubmit(
    //       functionName,
    //       this.$el,
    //       this.result.tableUrl,
    //       this.result,
    //       this.uploadedFiles,
    //       this
    //     );
    //   } else {
    //     console.log("not valid");
    //   }
    // },
  },
};
</script>
<style>
/* it do magic to avoid second Scroll*/
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100% !important;
}
</style>